<template>
  <div class="home-section-wrap-landing">
    <section class="home-section-landing" style="display: block !important;" id="home-section-landing">
      <div class="container">
        <h1 class="module-title">Создание договора</h1>
        <div class="wrapper">
          <div :class="get_arrow_steps_class() + ' clearfix'">
            <div v-for="(step_i, index) in this.all_steps" :key="index + 1"
                 :class="'step ' + curr_class(index + 1)">
              <span>Шаг {{ index + 1 }} ({{ step_i.name }})</span>
            </div>
          </div>
          <div :class="prev_next_btn_class()" style="height: 30px; margin-top: 2rem; display: flex">
            <a v-if="this.curr_module !== 1" @click="prevStep()">
              <button class="btn-success btn-blue-success" style="width: 200px; padding: .5rem; border-radius: .5rem">
                Вернуться
              </button>
            </a>
            <a v-if="all_required_exist" @click="nextStep()">
              <button class="btn-success" style="width: 200px; padding: .5rem; border-radius: .5rem">
                <span v-if="this.curr_module === 3">Сохранить</span>
                <span v-else>Продолжить</span>
              </button>
            </a>
          </div>
        </div>
        <div class="aiia-wizard-step" style="width: 100%; margin-bottom: .5rem">
          <div>
            <h2>
              <span class="circle-number">{{ curr_module }}</span>
              <span style="font-size: 20px;">Инструкция</span>
              <span style="color: #23468c"> ({{ this.all_steps[curr_module - 1].name }})</span>
            </h2>
            <div class="step-content" style="padding-top: 0.7rem">
              <p>
                {{ this.all_steps[curr_module - 1].instruction }}
<!--                <span v-if="this.curr_module === 3 && this.new_act.type === 1">-->
<!--                  . Так же, вы можете-->
<!--                  <a class="custom-link" download="Шаблон для загрузки акта.xlsx" @click="showDownloadTemplate">скачать шаблон (excel)</a>-->
<!--                  , заполнить его и-->
<!--                  <input type="file" name="file" id="file" class="inputfile" :ref="'file_template'" v-on:change="uploadTemplate">-->
<!--                  <label for="file" class="upload-from-xls">загрузить обратно</label>-->
<!--                </span>-->

              </p>

              <modal name="download-template-modal">
<!--                <div class="modal-inner">-->
<!--                  <h2>Формирование шаблона для загрузки акта</h2>-->
<!--                  <input class="input-field-template" type="number" placeholder="Введите количество строк в акте" v-model="template_row_count">-->
<!--                  <select class="input-field-template" id="row-date-type-template"-->
<!--                          style="margin-left: 15%; margin-right: 15%; width: 70%"-->
<!--                          v-on:change="change_row_date_type_template()">-->
<!--                    <option value="" selected>Выберите тип даты строк*</option>-->
<!--                    <option value="2">Период оказания работ / услуг</option>-->
<!--                    <option value="1">Дата оказания работ / услуг</option>-->
<!--                  </select>-->
<!--                  <button class="upload-from-xls" @click="downloadTemplate" v-if="template_row_count !== '' && row_date_type_template !== ''">Скачать шаблон</button>-->
<!--                </div>-->
              </modal>
            </div>
          </div>
        </div>
        <div class="categories-container">
<!--          <button @click="getLoginField">asdasdasd</button>-->
<!--          <div v-show="this.curr_module === 1" class="flex-start-column gap-2r">-->
<!--/*            <iframe style="width: 100%; border: none; height: inherit" :src="'https://www.youtube.com/watch?v=39NjHtqfpa8'"></iframe>*/-->
<!--            <iframe style="margin-left: 20%; margin-right: 20%; width: 60%; height: 30vh" src="https://www.youtube.com/embed/NRYtaDCbQdA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--            <iframe style="margin-left: 20%; margin-right: 20%; width: 60%; height: 30vh" src="https://www.youtube.com/embed/NRYtaDCbQdA" title="YouTube video player" allowfullscreen></iframe>-->
<!--            <iframe style="margin-left: 20%; margin-right: 20%; width: 60%; height: 30vh" src="https://web.citypoint.ru/index.php" title="YouTube video player" allowfullscreen></iframe>-->
<!--            <iframe style="margin-left: 20%; margin-right: 20%; width: 60%; height: 70vh; border: 1px black solid" :src="iframeRef" id="myFrame"></iframe>-->
<!--          </div>-->
          <div v-show="this.curr_module === 1" class="flex-start-column gap-2r">
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-half gap-half">
                <label>Номер договора</label>
                <input class="input-act-params" placeholder="Введите номер договора*" v-model="new_scontract.number">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Предмет договора</label>
                <input class="input-act-params" placeholder="Введите наименование договора*" v-model="new_scontract.name">
              </div>
              <div class="flex-start-column div-half gap-half">
                <label>Срок оказания услуг (от)</label>
                <input class="input-act-params" placeholder="Введите cрок оказания услуг (от)*" v-model="new_scontract.contract_date_start"
                       onfocus="(this.type='date')">
              </div>
              <div class="flex-start-column div-half gap-half">
                <label>Срок оказания услуг (до)</label>
                <input class="input-act-params" placeholder="Введите cрок оказания услуг (до)*" v-model="new_scontract.contract_date"
                       onfocus="(this.type='date')">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-half gap-half">
                <label>Вид договора</label>
                <select class="input-act-params" v-model="new_scontract.type">
                  <option value="">Выберите вид договора*</option>
                  <option v-for="(contract_type, index) in this.types_list" :key="index" :value="contract_type">{{ contract_type }}</option>
                </select>
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Заказчик</label>
                <input class="input-act-params" list="impl_companies_list" placeholder="Начинайте вводить заказчика" v-model="new_scontract.impl_company_name" @change="getUsersImplByCompany">
                <datalist id="impl_companies_list">
                  <option value="">Выберите заказчика*</option>
<!--                  <option v-for="ens_tru in search_filter.ens_list" :key="ens_tru" :value="ens_tru">{{ ens_tru }}</option>-->
                  <option v-for="(impl_company, index) in this.impl_companies" :key="index" :title="impl_company.name"
                          :value="impl_company.name">

                  </option>
                </datalist>
<!--                <select class="input-act-params" v-model="new_scontract.impl_company" @change="getUsersImplByCompany">-->
<!--                  <option value="">Выберите заказчика*</option>-->
<!--                  <option v-for="(impl_company, index) in this.impl_companies" :key="index" :title="impl_company.name"-->
<!--                          :value="impl_company">-->
<!--                    {{ impl_company.name }} ({{ impl_company.bin }})-->
<!--                  </option>-->
<!--                </select>-->
              </div>
              <div class="flex-start-column div-half gap-half">
                <label>Куратор со стороны заказчика</label>
                <select class="input-act-params" v-model="new_scontract.impl_abp">
                  <option value="">Выберите куратора*</option>
                  <option v-for="(impl_user, index) in this.impl_users" :key="index" :title="impl_user.ouser.full_name"
                          :value="impl_user">
                    {{ impl_user.ouser.full_name }} ({{ impl_user.ouser.position }})
                  </option>
                </select>
              </div>

            </div>
<!--            <div class="flex-space-between gap-1r">-->

<!--              <div class="flex-start-column div-full gap-half">-->
<!--                <label>Сумма без НДС</label>-->
<!--                <input class="input-act-params" type="number" placeholder="Введите сумму договора (без НДС)*" v-model="new_scontract.total_without_nds">-->
<!--              </div>-->
<!--              <div class="flex-start-column div-half gap-half">-->
<!--                <label>Размер НДС</label>-->
<!--                <select class="input-act-params" v-model="new_scontract.nds">-->
<!--                  <option value="">Выберите размер НДС*</option>-->
<!--                  <option v-for="(nds_data, index) in this.nds_list" :key="index" :value="nds_data.value">{{ nds_data.name }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div v-show="this.curr_module === 2" class="flex-start-column gap-1r">
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label style="font-size: 25px">Тапсырысшының деректемелері</label>
              </div>
              <div class="flex-start-column div-full gap-half">
                <label style="font-size: 25px">Реквизиты заказчика</label>
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Компанияның атауы</label>
                <input class="input-act-params-small" placeholder="Компанияның атауы енгізіңіз*" v-model="new_scontract.requisites.impl.name_kaz">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Наименование орзанизации</label>
                <input class="input-act-params-small" placeholder="Введите наименование орзанизации*" v-model="new_scontract.requisites.impl.name">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Компанияның БСН</label>
                <input class="input-act-params-small" placeholder="Компанияның БСН енгізіңіз*" v-model="new_scontract.requisites.impl.bin">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>БИН организации</label>
                <input class="input-act-params-small" placeholder="Введите БИН организации*" v-model="new_scontract.requisites.impl.bin">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Компанияның мекенжайы</label>
                <input class="input-act-params-small" placeholder="Компанияның мекенжайын енгізіңіз*" v-model="new_scontract.requisites.impl.address_kaz">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Адрес организации</label>
                <input class="input-act-params-small" placeholder="Введите адрес организации*" v-model="new_scontract.requisites.impl.address">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>БСК</label>
                <input class="input-act-params-small" placeholder="БСК енгізіңіз*" v-model="new_scontract.requisites.impl.bik">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>БИК</label>
                <input class="input-act-params-small" placeholder="Введите БИК*" v-model="new_scontract.requisites.impl.bik">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>ЖСК</label>
                <input class="input-act-params-small" placeholder="ЖСК енгізіңіз*" v-model="new_scontract.requisites.impl.iik">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>ИИК</label>
                <input class="input-act-params-small" placeholder="Введите ИИК*" v-model="new_scontract.requisites.impl.iik">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Кбе</label>
                <input class="input-act-params-small" placeholder="Кбе енгізіңіз*" v-model="new_scontract.requisites.impl.kbe">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Кбе</label>
                <input class="input-act-params-small" placeholder="Введите Кбе*" v-model="new_scontract.requisites.impl.kbe">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Банктің атауы</label>
                <input class="input-act-params-small" placeholder="Банктің атауын енгізіңіз*" v-model="new_scontract.requisites.impl.bank_name_kaz">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Название банка</label>
                <input class="input-act-params-small" placeholder="Введите название банка*" v-model="new_scontract.requisites.impl.bank_name">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Компанияның телефоны</label>
                <input class="input-act-params-small" placeholder="Компанияның телефонын енгізіңіз*" v-model="new_scontract.requisites.impl.phone">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Телефон организации</label>
                <input class="input-act-params-small" placeholder="Введите телефон организации*" v-model="new_scontract.requisites.impl.phone">
              </div>
            </div>
            <div class="flex-space-between gap-1r">
              <div class="flex-start-column div-full gap-half">
                <label>Электрондық пошта</label>
                <input class="input-act-params-small" placeholder="Электрондық поштаны енгізіңіз*" v-model="new_scontract.requisites.impl.email">
              </div>
              <div class="flex-start-column div-full gap-half">
                <label>Электронная почта</label>
                <input class="input-act-params-small" placeholder="Введите телефон организации*" v-model="new_scontract.requisites.impl.email">
              </div>
            </div>
          </div>
          <div v-show="this.curr_module === 3" class="flex-start-column gap-2r">
            <div class="dropzone">
              <input type="file" class="input-field" id="input-field-multiple" ref="files_additional" multiple
                     accept=".pdf, image/png, image/jpeg" @change="reuploadVl1"/>
              <div v-if="!new_scontract.uploading_other" class="call-to-action">
                Перетащите дополнительные файлы сюда или нажмите, чтобы загрузить
                <p>
                  <small>максимальный размер файлов: 5MB</small><br>
                  <small>максимальный размер всех файлов: не больше 100 мб</small>
                </p>
                <p>
                  <small>типы загружаемых файлов: .pdf, .png, .jpeg</small>
                </p>
              </div>
              <div v-if="new_scontract.uploading_other" class="uploaded-msg">
                Файлы успешно загружены
              </div>
            </div>
            <modal name="file-modal-multiple">
              <div class="modal-inner">
                <div>Следует загружать следующие типы файлов: pdf, png, jpg</div>
                <button @click="closeModalMultuple">Понятно</button>
              </div>
            </modal>
          </div>
<!--          <div v-show="this.curr_module === 4" class="flex-start-column gap-1r">-->
<!--            <div class="innernavbar-wrap p-10" style="padding-top: 0 !important;">-->
<!--              <div class="item-wrap" style="padding-bottom: 30px !important;">-->
<!--                <div class="item left-item">-->
<!--                  <ul style="display: flex; flex-direction: row; justify-content: center; gap: 1rem">-->
<!--                    <li>-->
<!--                      <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"-->
<!--                              :class="fast_active('1')"-->
<!--                              @click="change_fast_filter('1')">-->
<!--                        Приложение 1 (рус)-->
<!--                      </button>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <button class="filter-btn transition-all ease-in-out duration-150 transform hover:scale-110 bg-indigo-500 hover:bg-indigo-600 px-5 py-1 rounded-lg max-h-12 whitespace-nowrap"-->
<!--                              :class="fast_active('2')"-->
<!--                              @click="change_fast_filter('2')">-->
<!--                        Приложение 1 (каз)-->
<!--                      </button>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flex-space-between gap-1r">-->
<!--                <div class="flex-start-column gap-1r" v-for="ngdu in this.ngdu_obj" :key="ngdu.id">-->
<!--                  <div class="flex-start-column div-full gap-half">-->
<!--                    <label style="font-size: 25px" v-if="fast_filter === '1'">{{ ngdu.ngdu_name_rus }}</label>-->
<!--                    <label style="font-size: 25px" v-else>{{ ngdu.ngdu_name_kaz }}</label>-->
<!--                  </div>-->
<!--                  <div class="flex-start-column div-full gap-half" v-for="price in ngdu.prices" :key="price.id">-->
<!--                    <label v-if="fast_filter === '1'">{{ price.place_name_rus }}</label>-->
<!--                    <label v-else>{{ price.place_name_kaz }}</label>-->
<!--                    <input v-if="fast_filter === '1'" class="input-act-params-small" type="number" placeholder="Введите стоимость (тг/сутки)" v-model="price.cost">-->
<!--                    <input v-else class="input-act-params-small" type="number" placeholder="Бағасын енгізіңіз (тг/тәулік)" v-model="price.cost">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "CreateSContract",
  data() {
    return {
      iframeRef: 'http://gps.skvid.kz/',
      active: true,
      curr_module: 1,
      fast_filter: "1",
      new_scontract: {
        number: 'Неизвестно',
        impl_company_name: '',
        name: '',
        contract_date_start: '',
        contract_date: '',
        impl_company: {},
        type: '',
        total_without_nds: '',
        nds: '',
        impl_abp: '',
        uploading_ts: false,
        uploading_other: false,
        requisites: {
          zak: {
            name: 'Акционерное общество "Эмбамунайгаз"',
            bin: '120240021112',
            address: 'Атырауская область, Атырау Г.А., Валиханова, 1',
            bik: 'HSBKKZKX',
            iik: 'KZ876010141000156926',
            bank_name: 'АО «Народный сберегательный банк Казахстана»',
            phone: '+7 (712) 299-3426',
            kbe: '',
            email: '',
          },
          impl: {
            name: '',
            name_kaz: '',
            bin: '',
            address: '',
            address_kaz: '',
            bik: '',
            iik: '',
            bank_name: '',
            bank_name_kaz: '',
            phone: '',
            kbe: '',
            email: '',
          },
        }
      },
      impl_companies: [],
      ngdu_obj: [],
      impl_users: [],
      types_list: [
          'Годовой договор',
          'Долгосрочный договор',
      ],
      nds_list: [
        {
          name: '12 %',
          value: 12,
        },
        {
          name: 'НДС не облагается',
          value: 0,
        },
      ],
      all_steps: [
        {
          name: 'Основные параметры',
          instruction: 'На данном шаге введите основные параметры договора, формы в дальнейших шагах будут построены в зависимости от выбранных Вами параметров',
        },
        {
          name: 'Реквизиты сторон',
          instruction: 'На данном шаге введите реквизиты сторон, учтите, что некоторые поля заполнены автоматически по карточкам компаний',
        },
        {
          name: 'Файлы',
          instruction: 'На данном шаге прикрепите техническую спецификацию в формате PDF, и другие файлы, учтите, что данные файлы станут неотьемлимой частью договора',
        }
        // ,
        // {
        //   name: 'Приложения',
        //   instruction: 'На данном шаге заполните все приложения к договору согласно списку, необходимые для полноты данных договора',
        // },
        // {
        //   name: 'Таблица',
        //   instruction: 'На данном шаге введите основные табличные данные по акту выполненных работ и услуг, учтите, что некоторые поля расчитываются автоматически',
        // }
      ],
      stat_gov: {
        "success": true,
          "obj": {
            "id": 0,
                "bin": "191040016927",
                "name": "ТОО \"ПРОДЕВ\"",
                "registerDate": "2019-10-14T18:00:00.000+0000",
                "okedCode": "62099",
                "okedName": "Другие виды деятельности в области информационных технологий и информационных систем, не включенные в другие группировки",
                "secondOkeds": null,
                "krpCode": "105",
                "krpName": "Малые предприятия (<= 5)",
                "krpBfCode": "105",
                "krpBfName": "Малые предприятия (<= 5)",
                "katoCode": "711110000",
                "katoId": 268015,
                "katoAddress": "Г.АСТАНА, РАЙОН АЛМАТЫ, УЛИЦА 23-31, Д 16, КВ 73",
                "fio": "БАГДАТОВ МУРАТ БЕРИКОВИЧ",
                "ip": false
          },
        "description": null
      },
    }
  },
  activated() {
    this.active = true;
  },

  deactivated() {
    this.active = false;
  },
  computed: {
    all_required_exist: function () {
      if (this.curr_module === 1) {
        if (this.new_scontract.number !== '' &&
            this.new_scontract.name !== '' &&
            this.new_scontract.contract_date_start !== '' &&
            this.new_scontract.contract_date !== '' &&
            this.new_scontract.type !== '' &&
            this.new_scontract.impl_company !== '' &&
            this.new_scontract.impl_abp !== '') {
          return true;
        } else {
          return false;
        }
      } else if (this.curr_module === 2) {
        if (this.new_scontract.requisites.impl.name !== '' &&
            this.new_scontract.requisites.impl.name_kaz !== '' &&
            this.new_scontract.requisites.impl.bin !== '' &&
            this.new_scontract.requisites.impl.address !== '' &&
            this.new_scontract.requisites.impl.address_kaz !== '' &&
            this.new_scontract.requisites.impl.bik !== '' &&
            this.new_scontract.requisites.impl.iik !== '' &&
            this.new_scontract.requisites.impl.bank_name !== '' &&
            this.new_scontract.requisites.impl.bank_name_kaz !== '' &&
            this.new_scontract.requisites.impl.email !== '' &&
            this.new_scontract.requisites.impl.kbe !== '' &&
            this.new_scontract.requisites.impl.phone !== '') {
          return true;
        } else {
          return false;
        }
      } else if (this.curr_module === 3) {
        return true;
      } else if (this.curr_module === 4) {
        return true;
      }
      return true;
    },
  },
  created() {
    this.getAllImplCompanies();
    this.getAllNgduList();
  },
  methods: {
    createSContract() {
      let formData = new FormData();
      // console.log(this.$refs['files_additional'].files);
      // let file = this.$refs['ts_file'].files[0];
      // formData.append('ts_file', file);

      for (let i = 0; i < this.$refs['files_additional'].files.length; i++) {
        let file = this.$refs['files_additional'].files[i];
        formData.append('files_additional-' + i, file);
      }

      const new_scontract = JSON.stringify(this.new_scontract);
      formData.append('new_scontract', new_scontract);
      const ngdu_obj = JSON.stringify(this.ngdu_obj);
      formData.append('ngdu_obj', ngdu_obj);

      this.$store.dispatch("createSContract", formData)
          .then((response) => {
            // console.log('sendSignXml-middle');
            console.log('response');
            console.log(response);
            if (response.data.new_scontract_id) {
              this.$store.dispatch('addNotification', {
                type: 'success',
                message: response.data.detail,
              }).then(() => {
                router.push({name: 'scontractview', params: {id: response.data.new_scontract_id}});
              })
            } else {
              this.$store.dispatch('addNotification', {
                type: 'error',
                message: response.data.detail
              })
            }
          })
          .catch((error) => {
            this.$store.dispatch('addNotification', {
              type: 'error',
              message: error.response.data.detail
            });
          });
    },
    // getLoginField() {
    //   var iframe = document.getElementById("myFrame");
    //   var elmnt = iframe.contentWindow.document.getElementById("user");
    //   // let a = document.getElementById('user');
    //   elmnt.value = 'MBAGDATOV';
    //   alert(elmnt.value);
    // },
    getUsersImplByCompany(){
      console.log(this.new_scontract.impl_company_name);
      this.new_scontract.impl_company = {};
      for (let i = 0; i < this.impl_companies.length; i++) {
        let company = this.impl_companies[i];
        if (company.name === this.new_scontract.impl_company_name) {
          this.new_scontract.impl_company = company;
          break;
        }
      }
      if (this.new_scontract.impl_company.bin !== '') {
        let formData = {
          bin: this.new_scontract.impl_company.bin,
        };
        this.$store.dispatch("getUsersImplByCompany", formData).then((response) => {
          this.impl_users = response.data;
          this.new_scontract.impl_abp = '';
          this.new_scontract.requisites.impl.name = this.new_scontract.impl_company.name;
          this.new_scontract.requisites.impl.bin = this.new_scontract.impl_company.bin;
          this.new_scontract.requisites.impl.address = this.new_scontract.impl_company.address;
          if (this.new_scontract.impl_company.phone !== 'Отсутствует') {
            this.new_scontract.requisites.impl.phone = this.new_scontract.impl_company.phone;
          }
        }).catch((error) => {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message: error.response.data.detail,
          })
        });
      }
    },
    getAllImplCompanies() {
      this.$store.dispatch("getAllImplCompanies").then((response) => {
        this.impl_companies = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    getAllNgduList() {
      this.$store.dispatch("getAllNgduList").then((response) => {
        this.ngdu_obj = response.data;
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
    },
    closeModalOne() {
      this.$modal.hide("file-modal-one");
    },
    closeModalMultuple() {
      this.$modal.hide("file-modal-multiple");
    },
    reuploadVl1() {
      // const file = this.$refs.file.files[0];
      let fileName = document.getElementById("input-field-multiple").value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


      if (!(extFile === "png" || extFile === "pdf" || extFile === "jpg")) {
        this.$modal.show("file-modal-multiple");
        this.file = null;
      } else {
        this.new_scontract.uploading_other = true;
      }
    },
    reuploadVlTS() {
      // const file = this.$refs.file.files[0];
      let fileName = document.getElementById("input-field-one").value,
          idxDot = fileName.lastIndexOf(".") + 1,
          extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


      if (!(extFile === "pdf")) {
        this.$modal.show("file-modal-one");
        this.file = null;
      } else {
        this.new_scontract.uploading_ts = true;
      }
    },
    check_contract_date() {
      let today = new Date();
      let contract_date = new Date(this.new_scontract.contract_date)

      if (this.monthDiff(contract_date, today) > 2 || this.monthDiff(today, contract_date) > 2) {
        this.$store.dispatch('addNotification', {
          type: 'warning',
          message: 'Дата договора отличается от текущей даты больше чем на 2 месяца',
        });
      }
    },
    check_contract_date_start() {
      let today = new Date();
      let check_contract_date_start = new Date(this.new_scontract.check_contract_date_start)

      if (this.monthDiff(check_contract_date_start, today) > 2 || this.monthDiff(today, check_contract_date_start) > 2) {
        this.$store.dispatch('addNotification', {
          type: 'warning',
          message: 'Дата договора отличается от текущей даты больше чем на 2 месяца',
        });
      }
    },
    fast_active(to_filter) {
      if (to_filter.toString() === this.fast_filter.toString()) {
        return 'active';
      }
      return '';
    },
    change_fast_filter(curr_filter) {
      this.fast_filter = curr_filter;
    },
    curr_class(arg) {
      if (this.curr_module === arg) {
        return 'current';
      } else if (this.curr_module > arg) {
        return 'done';
      }
      return '';
    },
    prevStep() {
      if (this.curr_module > 1) {
        this.curr_module -= 1;
      }
    },
    nextStep() {
      if (this.curr_module < 3) {
        this.curr_module += 1;
      } else {
        this.createSContract();
      }
    },
    prev_next_btn_class() {
      if (this.curr_module === 1) {
        return 'flex-end';
      } else {
        return 'flex-space-between';
      }
    },
    get_arrow_steps_class() {
      return 'arrow-steps-short';
    }
  },
  mounted() {
    // document.documentElement.appendChild(this.iframeRef);
  },

  beforeDestroy() {
    // document.documentElement.removeChild(this.iframeRef);
  },
}
</script>

<style scoped>
@import "../assets/css/Nunito.css";
@import "../assets/css/josefin.css";
iframe {
  position: fixed;
  z-index: 99;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.upload-from-xls {
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  background-color: #00aacc;
  color: white;
  border-radius: 5px;
}

.hidden-element {
  display: none !important;
}

.required-cell {
  /*  */
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.m-table-act {
  width: 100%;

  text-align: center;
  font-size: x-small;
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act td {
  border: 1px solid black;
  border-collapse: collapse;
}

.m-table-act th {
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  border: 1px solid black;
  border-collapse: collapse;
}

.input-field-default {
  width: 100%;
  padding: 10px;
  border: 1px solid gray;
  text-align: center;
  font-size: 1rem;
  outline: none;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.input-field-template {
  width: 300px !important;
  padding: 10px;
  border: 1px solid gray;
  text-align: center;
  font-size: 1rem;
  outline: none;
  margin: 0.5rem 0 1rem;
  border-radius: 10px;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.no-border-right {
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.m-input-cell {
  width: 100%;
  /*border-width: 1px;*/
  height: 35px;
  text-align: center;
}

.m-textarea-cell {
  display: block;
  width: 100%;
  padding: .5rem;
  /*border-width: 1px;*/
  height: 72px;
  text-align: left;
  resize: none;
}

.m-input-cell-big {
  display: block;
  width: 100%;
  /*border-width: 1px;*/
  height: 72px;
  text-align: center;
}

.dropzone {
  /*margin-top: 3rem;*/
  height: 250px;
  min-height: 250px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border: 2px dashed gray;
  outline-offset: 10px;
  background: #d9e3f7;
  color: dimgray;
}

.input-field {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  cursor: pointer;
}

.dropzone:hover {
  background: lightblue;
  transition: all 300ms ease;
}

.dropzone .call-to-action {
  font-size: 1.5rem;
  text-align: center;
  padding: 70px 0;
}

.call-to-action > div {
  font-size: 1rem;
  padding: 1rem 2rem;
  background: #00aacc;
  max-width: 300px;
  margin: 1rem auto;
  border-radius: 20px;
  color: #fff;
}

.uploaded-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.add_double_file {
  padding: .5rem;
  margin: .5rem;
  background: #23468c;
  color: white;
  border-radius: 10px;
}

.add_row_btn {
  padding: .5rem;
  margin: 1rem;
  background: #0c8;
  width: 2.6rem;
  font-size: 1rem;
  color: white;
  border-radius: 100%;
}

.rm_row_btn {
  padding: .5rem;
  margin: 1rem;
  background: rgba(255, 0, 0, 0.76);
  width: 2.6rem;
  font-size: 1rem;
  color: white;
  border-radius: 100%;
}

.rm_row_btn_small {
  /*padding: .5rem;*/
  margin: 0.4rem;
  background: rgba(255, 0, 0, 0.76);
  width: 1.5rem;
  font-size: 1rem;
  color: white;
  border-radius: 100%;
}

.rm_row_btn_small:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.modal-inner {
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.modal-inner-short {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

@media only screen and (max-width: 1366px) {
  .title-img {
    width: 300px;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1500px;
  }
}

@media (min-width: 2000px) {
  .container {
    width: 1700px;
  }
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.input-act-params {
  background: white;
  height: 60px;
  /*border: 1px solid gray;*/
  border-radius: .5rem;
  width: 100% !important;
  padding: 1rem;
}

.input-act-params-small {
  background: white;
  height: 50px;
  /*border: 1px solid gray;*/
  border-radius: .5rem;
  width: 100% !important;
  padding: 1rem;
}

input[type=checkbox] {
  appearance: none;
}

input[type=checkbox]:checked + label {
  border: 0.3rem solid #06aef0;
  box-shadow: 0 0 5px #333;
  opacity: 1;
}

.selected-items-wrapper {
  width: 300px;
  height: calc(100% - 300px);
  position: absolute;
  top: 300px;
  right: -300px;
  opacity: 0.8;
  padding: 1rem;
  overflow-y: auto;
  color: gray;
}

.selected-items-wrapper::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.selected-items-wrapper::-webkit-scrollbar-track {
  border-radius: 15px;
}

.selected-items-wrapper::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.categories-container {
  width: 100%;
  padding-top: 2rem;
}

.circle-number {
  border-radius: 300px;
  background: #23468c;
  color: #fff;
  float: left;
  font-size: 3rem;
  height: 80px;
  margin-right: 10px;
  margin-top: 5px;
  text-align: center;
  width: 80px;
  font-weight: bold;
  padding-top: 8px;
}

.module-title {
  padding-top: 30px;
  font-size: 25px;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  background: inherit;
}

.wrapper {
  display: table-cell;
  height: 170px;
  vertical-align: middle;
}

a, a:active {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #999;
}

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 325px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps-short .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 220px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

@media (max-width: 2000px) {
  .arrow-steps .step {
    min-width: 480px !important;
  }
  .arrow-steps-short .step {
    min-width: 360px !important;
  }
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #e4e9f7;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #23468c;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #23468c;
}



.arrow-steps-short .step:after,
.arrow-steps-short .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps-short .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #e4e9f7;
  z-index: 0;
}

.arrow-steps-short .step:first-child:before {
  border: none;
}

.arrow-steps-short .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps-short .step span {
  position: relative;
}

.arrow-steps-short .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps-short .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps-short .step.current {
  color: #fff;
  background-color: #23468c;
}

.arrow-steps-short .step.current:after {
  border-left: 17px solid #23468c;
}

</style>